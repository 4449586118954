<template>
  <div>
    <el-page-header :content="'设备 ['+imei+'] 轨迹'" @back="goBack" style="margin-bottom:10px"></el-page-header>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t('health.select_date')">
              <el-date-picker
                  @change="handleChange"
                  :end-placeholder="$t('device.end_time')"
                  format="YYYY-MM-DD HH:mm"
                  :start-placeholder="$t('device.start_time')"
                  type="datetimerange"
                  :disabled-date="disabledDate"
                  v-model="date"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="loadData" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="handleClear" plain type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <div class="mapcont">
        <div id="container" style="width:100%;min-height:600px;"></div>
        <div
            class="panelist"
            style="position: fixed;width:280px;min-height:600px;height:700px;top:15%;right:10px;overflow-y:auto;"
        ></div>
      </div>
    </el-card>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import data from "../../model/data";

export default {
  name: "dataTrack",
  data() {
    return {
      imei: this.$route.query.imei,
      location: [],
      longitude: "",
      latitude: "",
      start_time: "",
      end_time: "",
      date: "",
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const ret = await data.location({
        imei: this.imei,
        start_time: this.start_time,
        end_time: this.end_time
      });
      if (ret.code === 200) {
        this.location = ret.data.list;
        this.longitude = ret.data.longitude;
        this.latitude = ret.data.latitude;
        this.showMap();
      } else {
        this.$message.warning(ret.message);
      }
    },
    handleChange(event) {
      this.date = event;
      this.start_time = event[0];
      this.end_time = event[1];
    },
    handleClear() {
      this.date = "";
      this.start_time = "";
      this.end_time = "";
      this.loadData();
    },
    async showMap() {
      document.getElementsByClassName("panelist")[0].innerHTML = "";
      const AMap = await AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
      const option = {
        resizeEnable: true,
        zoom: 15,
        zoomToAccuracy: true,
        center: []
      };
      if (this.longitude) {
        option.center = [this.longitude, this.latitude];
        this.map = new AMap.Map("container", option);
        const marker = new AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: [this.longitude, this.latitude],
          title: this.imei
        });
        // 将以上覆盖物添加到地图上
        // 单独将点标记添加到地图上
        this.map.add(marker);
      } else {
        this.map = new AMap.Map("container", option);
      }
      let chartData = [];
      this.location.forEach(value => {
        chartData.push([value.longitude, value.latitude]);
      });
      let len = chartData.length;
      for (let i = 0; i < len - 1; i++) {
        let startPoint = chartData[i];
        let endPoint = chartData[i + 1];

        var divhtml =
            "<b>" +
            this.location[i].time +
            " — " +
            this.location[i + 1].time +
            '</b><div class="panel" id="panel_' +
            i +
            '"></div>';
        document.getElementsByClassName("panelist")[0].innerHTML += divhtml;

        let option = {
          map: this.map,
          panel: "panel_" + i,
          hideMarkers: true
        };
        if (this.location[i + 1].transport === 1) {
          this.map.plugin("AMap.Driving", () => {
            // 自驾导航
            option.policy = AMap.DrivingPolicy.LEAST_TIME
            let driving = new AMap.Driving(option);
            // eslint-disable-next-line no-unused-vars
            driving.search(startPoint, endPoint);
          });
        } else if (this.location[i + 1].transport === 2) {
          this.map.plugin("AMap.Transfer", () => {
            // 公交导航
            option.policy = AMap.TransferPolicy.LEAST_TRANSFER
            let transfer = new AMap.Transfer(option);
            // eslint-disable-next-line no-unused-vars
            transfer.search(startPoint, endPoint);
          });
        } else if(this.location[i + 1].transport === 3){
          this.map.plugin("AMap.Riding", () => {
            // 骑行导航
            let transfer = new AMap.Riding();
            // eslint-disable-next-line no-unused-vars
            transfer.search(startPoint, endPoint);
          });
        }else {
          this.map.plugin("AMap.Walking", () => {
            // 步行导航
            let walking = new AMap.Walking(option);
            // eslint-disable-next-line no-unused-vars
            walking.search(startPoint, endPoint);
          });
        }
      }
    },
    goBack() {
      this.$router.replace({
        path: "/data/home",
        query: {
          imei: this.imei
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
